.feedback-form {
  width: 100%;
}

.feedback-form__layout-wr {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-rows: repeat(3, auto);
  -ms-grid-rows: auto 30px auto 30px auto;
  grid-auto-rows: 1fr;
}


@media (--viewport-small-mobile) {
  .feedback-form__layout-wr {
    grid-template-columns: 1fr;
    grid-auto-rows: 0.6fr;
  }
}

.feedback-form__layout {
  position: relative;
}

.feedback-form__layout--name {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  grid-column: 1;
  grid-row: 1;
}

.feedback-form__layout--email {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
  grid-column: 1;
  grid-row: 2;
}

.feedback-form__layout--subject {
  -ms-grid-column: 1;
  -ms-grid-row: 5;
  grid-column: 1;
  grid-row: 3;
}

.feedback-form__layout--feedback {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-column: 2;
  grid-row: 1 / 3;
}

.feedback-form__layout--submit {
  -ms-grid-column: 3;
  -ms-grid-row: 5;
  grid-column: 2;
  grid-row: 3;
}

.feedback-form__result-layout {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  grid-column: 1 / 3;
  grid-row: 1 / 4;
}

.feedback-form__msg-error {
  padding: 20px 10px;
  text-align: justify;
}

@media (--viewport-tablet) {
  .feedback-form {
    margin-top: 20px;
  }
}

@media (--viewport-small-mobile) {
  .feedback-form__layout {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    -ms-grid-rows: auto 30px auto 30px auto 30px auto 30px auto 30px auto 30px;
  }

  .feedback-form__layout--name {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
  }

  .feedback-form__layout--email {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-column: 1;
    grid-row: 2;
  }

  .feedback-form__layout--subject {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
    grid-column: 1;
    grid-row: 3;
  }

  .feedback-form__layout--feedback {
    -ms-grid-column: 1;
    -ms-grid-row: 7;
    -ms-grid-row-span: 9;
    grid-column: 1;
    grid-row: 4 / 6;
  }

  .feedback-form__layout--submit {
    -ms-grid-column: 1;
    -ms-grid-row: 11;
    grid-column: 1;
    grid-row: 6;
  }

  .feedback-form__result-layout {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 11;
    grid-column: 1;
    grid-row: 1 / 7;
  }
}
