.client {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  margin: 20px 40px;
}

.client__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -40px;
}

.client__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.client__logo > img {
  max-width: 100%;
}

@media (--viewport-small-mobile) {
  .feature__list .feature {
    width: 100%;
  }

  .client__list {
    margin: 0;
  }
}
