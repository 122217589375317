.top-subscribe  {
  padding: 40px 70px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--shadow-default);
  color: var(--color-text-dark);
}

.is-app-ready .top-subscribe {
  transition: transform 0.25s ease-in-out;
}

.has-scrolled .top-subscribe {
  transform: translateY(-40px);
}

.form_top {
  width: 100%;
}

.form_top__layout {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr) ;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-rows: repeat(2, auto);
  -ms-grid-rows: auto 20px auto;
}

.form_top__layout--name {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  grid-column: 1;
  grid-row: 1;
}

.form_top__layout--email {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  grid-column: 2;
  grid-row: 1;
}

.form_top__layout--submit {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 3;
  grid-column: 1 / 3;
  grid-row: 2;
}

.form_top__result-layout {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

@media (--viewport-tablet) {
  .top-subscribe {
    padding: 30px 60px;
  }

  .form_top {
    margin-top: 20px;
  }
}

@media (--viewport-mobile) {
  .has-scrolled .top-subscribe {
    transform: translateY(-20px);
  }
}

@media (--viewport-small-mobile) {
  .top-subscribe {
    padding: 30px 40px;
  }

  .has-scrolled .top-subscribe {
    transform: translateY(-90px);
  }

  .form_top__layout {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    -ms-grid-rows: auto 12px auto 12px auto;
  }

  .form_top__layout--name {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
  }

  .form_top__layout--email {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-column: 1;
    grid-row: 2;
  }

  .form_top__layout--submit {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
    grid-column:1;
    grid-row: 3;
  }

  .form_top__result-layout {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    grid-column: 1;
    grid-row: 1 / 4;
  }
}
