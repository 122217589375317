.section {
  position: relative;
  z-index: 1;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  background: var(--gradient-blue);
  color: var(--color-text-default);
}

.section--light {
  background: var(--color-white);
  color: var(--color-text-dark);
}

.section--main {
  min-height: 100vh;
}

.section--vertical-centered {
    display: flex;
    align-items: center;
}

.section--overflow-hidden {
  overflow: hidden;
}

.section__wrapper {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: var(--width-wrapper-max);
  height: 100%;
  padding: var(--header-height) 0;
}

.section__wrapper--noident {
  padding: 0;
}

.section__wrapper--noident-top {
  padding-top: 0;
}

.section__wrapper--center {
  justify-content: center;
}

.section__back {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section__back--bottom {
  top: unset;
  bottom: 0;
}

.section__back--main_picture {
  background: url('./images/hero.svg') no-repeat;
  background-size: 100% 100%;
}

.section__back--feedback {
  background: url('./images/feedback.svg') no-repeat;
  background-size: 100% 100%;
  transform: translateY(-15%) translateX(35%) scale(1);
}

.section__back--database {
  background: url('./images/database.svg') no-repeat;
  background-size: 100% 100%;
  transform: translateX(35%);
}

.section__back--framework {
  background: url('./images/frameworkBG.svg') no-repeat;
  background-size: 100% 100%;
  transform: translateX(-15%);
}

@media (--viewport-tablet) {
  .section--main {
    padding-top: 20px;
    min-height: calc(100vh - 20px);
  }

  .section__back--main_picture {
    background: url('./images/hero_mobile.svg') no-repeat;
    transform: translateY(-5%) translateX(14%) scale(2);
  }

  .section__back--feedback {
    transform: translateY(-30%) translateX(40%) scale(1.5);
  }
}

@media (--viewport-mobile) {
  .section__back--main_picture {
    transform: translateY(113%) translateX(3%) scale(4);
  }

  .section__back--feedback {
    transform: translateY(-15%) translateX(35%) scale(1.5);
  }

  .section__back--framework {
    transform: none;
  }
}

@media (--viewport-small-mobile) {
  .section__back--main_picture {
    transform: translateY(100%) scale(3);
  }

  .section__back--database {
    transform: translateX(60%) scale(2);
  }
}
