.logo {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0;
  color: var(--color-text-light);
}

.logo:focus {
  outline: 0;
}

.logo.focus-visible {
  background-color: var(--color-focus);
}

.logo__icon {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background-image: url('./icons/cycle.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.logo__label {
  margin-left: 10px;
  font-size: 22px;
  font-weight: 400;
}

.logo__label > em {
  font-weight: 700;
  font-style: normal;
}

.logo__header > em {
  font-weight: 500!important;
}

.logo-small .logo__icon {
  width: 34px;
  height: 34px;
}

.logo-small .logo__label {
  margin-left: 8px;
  font-size: 18px;
}

.is-inner .logo__label, .has-scrolled .logo__label {
  color: var(--color-text-dark);
}

@media (--viewport-tablet) {
  .is-nav-opened .logo__label {
    color: var(--color-text-dark);
  }
}

@media (--viewport-small-mobile) {
  .logo__label {
    margin-left: 8px;
    font-size: 18px;
  }

  .is-nav-opened .logo__label {
    color: var(--color-text-light);
  }

  .logo__icon {
    width: 35px;
    height: 35px;
  }
}
