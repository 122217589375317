.header-search {
  position: relative;
  z-index: 1;
  width: 210px;
  flex-shrink: 0;
}

.header-search__form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-search__query {
  appearance: none;
  width: 150px;
  height: 36px;
  margin: 0;
  padding: 6px 6px 6px 36px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-clip: padding-box;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-text-light);
  transition: width .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.header-search__query::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-image: url('./icons/thick_cross_light.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
}

.header-search__query::placeholder {
  color: var(--color-search-placeholder);
  opacity: 0.5;
}

.header-search__query:not(:placeholder-shown) {
  width: 210px;
}

.header-search__query:focus {
  width: 210px;
  background-color: var(--color-search-bg-focus);
  outline: 0;
}

.header-search__query:-webkit-autofill,
.header-search__query:-webkit-autofill:hover {
  box-shadow: inset 0 0 0 20px var(--color-search-result-shadow);
  -webkit-text-fill-color: var(--color-search-result-text);
}

.is-inner .header-search__query, .has-scrolled .header-search__query {
  color: var(--color-text-dark);
}

.is-inner .header-search__query::-webkit-search-cancel-button, .has-scrolled .header-search__query::-webkit-search-cancel-button {
  background-image: url('./icons/thick_cross_dark.svg');
}

.is-inner .header-search__query::placeholder, .has-scrolled .header-search__query::placeholder {
  color: var(--color-text-dark);
}

.header-search__query--icon {
  background-image: url('icons/search_light.svg');
  background-repeat: no-repeat;
  background-size: 14px 13px;
  background-position: 10px 10px;
}

.is-inner .header-search__query--icon,
.has-scrolled .header-search__query--icon {
  background-image: url('icons/search_dark.svg');
}

.header-search__results {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200%;
  max-height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--color-search-result-text);
  background-color: var(--color-search-result-bg);
  box-shadow: inset 0 0 0 1px var(--color-search-result-shadow), var(--shadow-default);
  border-radius: 4px;
}

.header-search__results.is-opened {
  max-height: 240px;
}

.header-search__results li {
  margin: 0;
  padding: 0;
}

.header-search__results li:not(:first-child) {
  border-top: 1px solid var(--color-search-result-separator);
}

.header-search__results a {
  display: block;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-search-result-link);
}

.header-search__results a:hover {
  background-color: var(--color-search-result-focus);
}

.header-search__results a:focus {
  background-color: var(--color-search-result-focus);
  outline: 0;
}

.header-search__results a.focus-visible {
  background-color: var(--color-search-result-focus);
}

.header-search__results small {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray600);
}

.header-search__results small,
.header-search__results span {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 15px;
}

.header-search__results mark {
  background-color: transparent;
  color: var(--color-text-dark);
}

@media (--viewport-tablet) {
  .header-search {
    margin: 0 10px 0 auto;
  }

  .is-nav-opened .header-search__query::placeholder {
    color: var(--color-text-dark);
  }

  .is-nav-opened .header-search__query {
    color: var(--color-text-dark);
  }

  .is-nav-opened .header-search__query::-webkit-search-cancel-button {
    background-image: url('./icons/thick_cross_dark.svg');
    background-color: var(--color-gray800);
  }

  .is-nav-opened .header-search__query--icon {
    background-image: url('icons/search_dark.svg');
  }
}

@media (--viewport-small-mobile) {
  .header-search {
    position: absolute;
    top: 100%;
    left: 5%;
    margin: 160px 0 0;
    width: 90%;
    height: 40px;
    display: none;
  }

  .is-nav-opened .header-search {
    display: block;
  }

  .header-search__query {
    border-bottom: 1px solid transparent;
  }

  .header-search__query:not(:placeholder-shown),
  .header-search__query:focus {
    width: 100%;
    border-bottom: 1px solid var(--color-gray800);
  }

  .is-nav-opened .header-search__query::placeholder {
    color: var(--color-text-light);
  }

  .is-nav-opened .header-search__query {
    color: var(--color-text-light);
  }

  .is-nav-opened .header-search__query::-webkit-search-cancel-button {
    background-image: url('./icons/thick_cross_light.svg');
    background-color: var(--color-blue600);
  }

  .is-nav-opened .header-search__query--icon {
    background-image: url('icons/search_light.svg');
  }

  .header-search__results {
    width: 100%;
    right: 50%;
    transform: translateX(50%);
  }
}
