.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px;
  max-width: 290px;
  margin-top: 60px;
  text-align: center;
}

.feature:hover {
  border-radius: 8px;
  box-shadow: var(--shadow-default);
}

.feature__list {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 28px 0 0;
  background-color: var(--color-white);
}

.feature__list .feature {
  width: 33.333%;
  align-items: flex-start;
  text-align: left;
}

.feature__icon {
  width: 72px;
  height: 72px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.feature__icon--polymorphic {
  background-image: url("icons/polymorphic.svg");
}

.feature__icon--objects {
  background-image: url("icons/objects.svg");
}

.feature__icon--eager {
  background-image: url("icons/eager.svg");
}

.feature__icon--runtime{
  background-image: url("icons/runtime.svg");
}

.feature__icon--mapping {
  background-image: url("icons/mapping.svg");
}

.feature__icon--graph {
  background-image: url("icons/graph.svg");
}

.feature__title {
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--color-text-dark);
}

.feature__text {
  margin-top: 10px;
  font-size: 15px;
  line-height: 24px;
  color: var(--color-text-dark);
}

@media (--viewport-small-desktop) {
  .feature__list {
    justify-content: space-around;
  }
}

@media (--viewport-tablet) {
  .feature {
    border-radius: 8px;
    box-shadow: var(--shadow-default);
  }

  .feature:nth-child(odd) {
    margin-right: 20px;
  }

  .feature__list {
    justify-content: center;
  }
}

@media (--viewport-mobile) {
  .feature {
    margin-top: 30px;
    margin-right: 0;
  }
}

@media (--viewport-small-mobile) {
  .feature__list .feature {
    width: 100%;
    margin-right: 0;
  }

  .feature__text {
    margin-left: 0;
    margin-right: 0;
  }
}
