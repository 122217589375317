.footer-subscribe {
  position: relative;
  margin-top: 30px;
}

.footer-subscribe__input {
    padding-right: 144px !important; /* button width (120) + margins (8) + default padding (16) */
}

.footer-subscribe__button {
  position: absolute;
  top: 0;
  right: 0;
  appearance: none;
  height: 32px;
  box-sizing: border-box;
  padding: 7px 20px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  background: var(--gradient-green);
  color: var(--color-text-light);
  cursor: pointer;
  overflow: hidden;
  width: 120px;
  margin: 4px;
}

.footer-subscribe__button:hover {
  background: var(--gradient-green-light);
}

.footer-subscribe__button:focus {
  outline: 0;
}
