.input-block {
  width: 100%;
}

.input-block__input {
  appearance: none;
  grid-area: sd;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid var(--color-gray700);
  border-radius: 4px;
  box-shadow: none;
  outline: 0;
  background-color: var(--color-white);
  color: var(--color-text-dark);
}

.input-block__input--light {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: var(--color-background-light);
  color: var(--color-text-light);
}

.input-block__input:hover, .input-block__input--light:focus {
  border-color: var(--color-green700);
}

.input-block__input--light:hover {
  border-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.15);
}

.input-block__input::placeholder {
  color: var(--color-gray700);
}

.has-error .input-block__input {
  border-color: var(--color-error)
}

.input-block__input--textarea {
  width: 100%;
  height: 100%;
  resize: none;
}

.input-block__error {
  display: none;
  position: absolute;
}

.feedback-form__layout .input-block__error {
    bottom: -25px;
}

.has-error .input-block__error {
  display: block;
  color: var(--color-error);
}
