.heading {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.heading--small {
  width: 100%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}

.heading--bordered {
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--color-gray800);
  text-align: left;
}

@media (--viewport-mobile) {
  .heading {
    font-size: 20px;
    line-height: 30px;
  }

  .heading--small {
    font-size: 18px;
    line-height: 25px;
  }
}
