.advantage {
  width: 100%;
  max-width: var(--width-text-max);
  color: var(--color-text-light);
}

.advantage--dark {
  color: var(--color-text-dark);
}

.advantage--centered {
  text-align: center;
}

.advantage--flex-start {
  justify-content: flex-start;
}

.advantage--small-margin-right {
  margin-right: 20px;
}

.advantage__heading {
  margin: 0;
  font-size: 40px;
  line-height: 54px;
  font-weight: 700;
}

.advantage__heading--subheading {
  font-size: 24px;
  line-height: 32px;
}

.advantage__brief {
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
}

.advantage__actions {
  display: flex;
  position: relative;
  margin-top: 30px;
}

@media (--viewport-small-desktop) {
  .advantage__heading {
    font-size: 34px;
    line-height: 52px;
  }

  .advantage__heading--subheading {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (--viewport-tablet) {
  .advantage__brief {
    margin-top: 15px;
  }
}

@media (--viewport-mobile) {
  .advantage__heading {
    font-size: 32px;
    line-height: 42px;
  }

  .advantage__heading--subheading {
    font-size: 20px;
    line-height: 28px;
  }

  .advantage__actions {
    margin-top: 30px;
  }
}
