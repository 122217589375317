.subscribe-form__result {
  width: 100%;
  height: 100%;
  background: var(--color-gray1000);
  border-radius: 4px;
}

.result_repeat {
  padding: 0 20px;
  text-align: center;
  background-color: var(--color-gray800);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media (--viewport-tablet) {
  .subscribe-form__result {
    margin-top: 30px;
  }
}

@media (--viewport-small-mobile) {
  .result_repeat {
    padding: 0 20px;
  }
}
