body, html {
    font-size: 100%;
    letter-spacing: .21px;
    font-weight: 300;
}

body {
    min-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
}

img, svg {
    vertical-align: middle;
}
img {
    border-style: none;
}

label {
    opacity: .9;
    text-transform: uppercase;
    color: #0f1430;
    letter-spacing: .3px;
}
label {
    display: inline-block;
    margin-bottom: .5rem;
}

.pt-7, .py-7 {
    padding-top: 6.25rem !important;
}

.pt-11 {
  padding-top: 10.25rem !important;
}

a:hover {
    color: #0056b3;
    text-decoration: none;
}

.form-control::placeholder {
    color: #b1b9ce;
    opacity: 1;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.1em + 2rem + 1px);
    padding: 1rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1;
    color: rgba(60,79,133,.77);
    background-color: #eff2ff;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn {
    display: inline-block;
    font-weight: 700;
    color: rgba(60,79,133,.77);
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0 solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.75rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.header-search__query--icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='15' xml:base='search_light.svg' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(1 1)' stroke='%23fff' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle cx='5.5' cy='5.5' r='5.5'/%3E%3Cpath d='M9.732 9.232l3.536 3.536' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 14px 13px;
    background-position: 10px 10px;
}
.header-search__query {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 150px;
    height: 36px;
    margin: 0;
    padding: 6px 6px 6px 36px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-clip: padding-box;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    color: var(--color-text-light);
    transition: width .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

.hero-section {
    height: 100vh;
    min-height: 750px;
    padding-bottom: 75px;
}

.hero-section .container {
    height: 100%;
}

.hero-section .container > div {
    height: 100%;
}

.bd-gradient {
    background: linear-gradient(119deg, #022C5C 0%, #7974B0 100%);
}

.bg-gradient-light {
    background: linear-gradient(-180deg, rgba(244, 243, 250, 0.00) 0%, #F6F6FD 100%);
}

.info-section .info-block {
    transition: transform .2s;
}

.hero-section .container,
.hero-section .container-fluid,
.hero-section .container-lg,
.hero-section .container-md,
.hero-section .container-sm,
.hero-section .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.btn-min-width {
    min-width: 225px;
}

.btn-success {
    opacity: .9;
    color: #fff;
}

.btn-success .icon {
    margin-right:.75rem
}

.btn-success {
    background-color: #43c8ad;
    border-color: #43c8ad;
}

.scrolled .info-section .info-block {
    transform: translateY(-75px);
}
.info-section .info-block {
    transition: transform .2s;
}
.shadowed-block {
    box-shadow: 0 8px 20px 0 rgba(50,73,126,.06);
    border-radius: .25rem;
}

.info-block .img-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 68px;
    height: 68px;
    margin-bottom: 1rem;
}

.instructions-section .nav-pills {
    border-right: 1px solid rgba(102,117,159,.1);
}

.instructions-section .nav-link::after {
    position: absolute;
    content: "";
    display: block;
    height: 1px;
    width: calc(100% - 2.5rem);
    background: rgba(102,117,159,.1);
    bottom: 0;
    right: 0;
}

code.bg-dark {
    background: #101a32;
    background-color: rgb(16, 26, 50);
    padding: 1rem .75rem;
    color: #fff;
    white-space: pre-wrap;
}

.nav-link {
    display: block;
    padding: 2rem 2.5rem;
}

.btn-success.focus, .btn-success:focus, .btn-success:hover {
    color: #fff;
    background-color: #34b198;
    border-color: #31a78f;
}

.contacts-section {
    padding: 5rem 0 0;
    margin-bottom: 200px;
}

.contacts-section .clients-block {
    margin-top: 1.5rem;
}

.contacts-section .img-wrapper {
    padding: 1rem;
}

.contacts-section .contact-form-block {
    width: 90%;
    max-width: 935px;
    margin: 0 auto;
    position: relative;
    top: 100px;
    background: #fff;
    padding: 3.75rem 6rem;
}

.section__wrapper .advantage {
    margin: 0 auto 32px;
    text-align: center;
}

.section__wrapper .advantage .advantage__heading {
    font-family: Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 1.875rem;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: -.39px;
    color: #262b44;
    margin-bottom: 5px;
}

.section__wrapper .advantage .advantage__brief {
    font-family: Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 16px;
    line-height: 1.75rem;
    font-weight: 300;
    letter-spacing: .21px;
    color: #7b88ad;
}

.section__wrapper .tile__group .tile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    max-width: 540px;
    padding: 55px 70px;
    background-color: #fff;
    box-shadow: 0 8px 20px 0 rgba(50,73,126,.06);
    border-radius: 4px;
    margin-top: 0!important;
}

.section__wrapper .tile__group .tile .tile__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px;
}

.section__wrapper .tile__group .tile .tile__header .tile__icon--framework {
    background-image: url(/images/roadrunner/framework.svg);
    width: 52px;
    height: 71px;
}

.section__wrapper .tile__group .tile .tile__header .tile__icon--cycleorm {
    background-image: url(/images/roadrunner/cycleorm.svg);
    width: 56px;
    height: 56px;
}

.section__wrapper .tile__group .tile .tile__header .tile__icon {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 25px;
}

.section__wrapper .tile__group .tile .tile__header .tile__title {
    font-family: Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 24px;
    line-height: 1.6;
    font-weight: 400;
    color: #262b44;
}

.section__wrapper .tile__group .tile .tile__content {
    height: 100%;
    text-align: left;
    font-family: Roboto,Helvetica Neue,Arial,sans-serif;
    font-weight: 300;
    color: #7b88ad;
    margin-bottom: 35px;
}

.section__wrapper .tile__group .tile .tile__content .tile__subtitle {
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 12px;
}

.section__wrapper .tile__group .tile .tile__content .tile__text {
    font-size: 14px;
    line-height: 24px;
}

.section__wrapper .tile__group .tile .tile__action {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.instructions-section .nav-link {
    position: relative;
    font-size: 1.375rem;
    color: #6776a0;
    line-height: 1.6;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #262b44;
    background-color: #fff;
}

.pb-7, .py-7 {
    padding-bottom: 6.25rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 5rem !important;
}
.pt-6, .py-6 {
  padding-top: 5rem !important;
}

.section__wrapper {
    padding: unset!important;
    display: block!important;
}

.section__wrapper .tile__group .tile .tile__action .btn::after {
    content: "";
    display: block;
    height: 10px;
    width: 7px;
    background-image: url(/images/roadrunner/arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 10px;
}

.section__wrapper .tile__group .tile .tile__action .btn {
    width: 210px;
    height: 56px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
}

.section-products {
    padding-bottom: 90px;
}

.title {
  text-transform: uppercase;
  margin-bottom: .5rem;
  opacity: .9;
  color: #0f1430;
  letter-spacing: .3px;
}

.rr-checkbox {
  display: inline-block;
  background: #7a7ce4;
  border-radius: 4px;
  width: 1.625rem;
  height: 1.625rem;
  position: relative;
  margin-right: .75rem;
}

.rr-checkbox::after {
  content: "\E903";
  font-family: icons;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@media(min-width:576px) {
    .hero-section .container,
    .hero-section .container-sm {
        max-width:540px
    }
}

@media(min-width:768px) {
    .hero-section .container,
    .hero-section .container-md,
    .hero-section .container-sm {
        max-width:720px
    }

    .hero-section .mt-md-6, .hero-section .my-md-6 {
        margin-top: 5rem !important;
    }
}

@media(min-width:992px) {
    .hero-section .container,
    .hero-section .container-lg,
    .hero-section .container-md,
    .hero-section .container-sm {
        max-width:960px
    }
}

@media(min-width:1200px) {
    .hero-section .container,
    .hero-section .container-lg,
    .hero-section .container-md,
    .hero-section .container-sm,
    .hero-section .container-xl {
        max-width:1140px
    }
}
