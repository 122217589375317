.columnized {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.columnized__column {
  box-sizing: border-box;
  width: 50%;
}

.columnized__column--2 {
  width: 20%;
}

.columnized__column--3 {
  width: 30%;
}

.columnized__column--4 {
  width: 40%;
}

.columnized__column--6 {
  width: 60%;
}

.columnized__column--8 {
  width: 80%;
}

.columnized__column > img {
  display: block;
  width: 100%;
}

.columnized__column--vertical {
  display: flex;
  align-items: center;
}

@media (--viewport-tablet) {
  .columnized__wrap--tablet {
    flex-wrap: wrap;
  }

  .columnized__column--tablet-7 {
    width: 70%;
  }
}

@media (--viewport-tablet) {
  .columnized__column--tablet-10 {
    width: 100%;
  }
}

@media (--viewport-small-mobile) {
  .columnized {
    flex-wrap: wrap;
  }

  .columnized__column--no-small-mobile {
    display: none;
  }

  .columnized__column--small-mobile-10 {
    width: 100%;
  }
}
