.error-content {
  margin-top: 100px;
  text-align: center;
}

.error-content__heading {
  margin-top: 0;
  font-size: 48px;
}

.error-content__actions {
  margin: 0 auto;
  width: 250px;
}


@media (--viewport-tablet) {
  .error-content__heading {
    font-size: 36px;
  }
}

@media (--viewport-mobile) {
  .error-content {
    margin-top: 50px;
  }

  .error-content img {
    width: 100%;
  }

  .error-content__heading {
    font-size: 24px;
  }

  .error-content__actions {
    width: 100%;
  }
}
