.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  appearance: none;
  margin: 0;
  padding: 10px 50px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  border-radius: 4px;
  box-shadow: var(--shadow-btn-default);
  text-decoration: none;
  background: var(--gradient-blue);
  color: var(--color-text-light);
  overflow: hidden;
  cursor: pointer;
}

.button:hover {
  background: var(--gradient-blue-light);
  box-shadow: var(--shadow-hover);
  color: var(--color-text-light);
}

.button:active {
  box-shadow: var(--shadow-active);
}

.button:focus {
  outline: 0;
}

.button--green {
  background: var(--gradient-green);
}

.button--green:hover {
  background: var(--gradient-green-light);
  color: var(--color-text-light);
}

.button--full-width {
  width: 100%;
}

.button.focus-visible {
  background: var(--color-focus);
}

.button--link::after {
  content: '';
  margin-left: 10px;
  width: 6px;
  height: 9px;
  background-image: url('icons/link.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.button--git::before {
  content: '';
  margin-left: 10px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-image: url('icons/git.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.button--send::before {
  content: '';
  margin-left: 10px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-image: url('icons/send_light.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ripple {
  position: absolute;
}

.pulse {
  background-color: var(--color-white);
  border-radius: 50%;
  opacity: 0.7;
  animation: pulse 0.7s ease-out;
  transform: scale(0);
  position: absolute;
}

@keyframes pulse {
  to {
    transform: scale(2);
    opacity: 0;
  }
}
