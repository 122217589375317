.result_success {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}

.result_success:before {
  content: '';
  margin: auto 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.result_success--icon-blue:before {
  width: 40px;
  height: 40px;
  background-image: url("icons/send_blue.svg");
}

.result_success--icon-green:before {
  width: 26px;
  height: 26px;
  background-image: url("icons/send_green.svg");
}

.result_success--icon-check:before {
  width: 80px;
  height: 80px;
  background-image: url("icons/check.svg");
}

.result_success__message {
  padding: 25px;
}

.result_success__message--footer {
  padding: 20px;
}

.result_success__title {
  margin: 0;
  font-weight: 700;
}

.result_success__title--small {
  font-size: 13px;
  line-height: 16px;
}

.result_success__title--large {
  font-size: 44px;
  line-height: 64px;
}

.result_success__desc {
  margin: 0;
}

.result_success__desc--small {
  font-size: 13px;
  line-height: 16px;
}

.feedback-form__result {
  background-color: var(--color-background-light);
}

@media (--viewport-tablet) {
  .result_success:before {
    margin-left: 15px;
  }
}

@media (--viewport-small-mobile) {
  .result_success {
    flex-direction: column;
  }

  .result_success:before {
    margin-top: 20px;
  }

  .result_success__message {
    text-align: center;
  }
}
