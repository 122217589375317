.header {
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 0;
}

.header__container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  will-change: background-color;
}

.is-inner .header__container {
  transition: none;
}

.has-scrolled .header__container,
.is-inner .header__container {
  background-color: var(--color-header-bg);
  border-bottom: var(--color-header-border);
  box-shadow: var(--shadow-default);
}

.is-app-ready .header__container {
  transition: background-color 0.25s;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--width-wrapper-max);
  height: var(--header-height-main);
}

.is-inner .header__wrapper {
  transition: none;
}

.has-scrolled .header__wrapper,
.is-inner .header__wrapper {
  height: var(--header-height);
}

.is-app-ready .header__wrapper {
  transition: height 0.25s ease-in-out;
}

.header-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header-nav__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-nav__item {
  position: relative;
  margin: 0 10px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: var(--color-text-light);
  text-decoration: none;
}

.header-nav__item:hover {
  color: var(--color-text-light);
}

.is-inner .header-nav__item, .has-scrolled .header-nav__item {
    color: var(--color-text-dark);
}

.header-nav__item::after {
  content: '';
  position: absolute;
  width: 0;
  overflow: hidden;
  height: 2px;
  background-color: var(--color-green700);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width .3s;
}

.header-nav__item:hover::after,
.header-nav__item--active::after {
  width: calc(100% - 20px);
}

.header-nav__item:focus {
  outline: 0;
}

.header-nav__item.focus-visible {
  background-color: var(--color-focus);
}

.header-nav-toggle {
  display: none;
}

@media (max-width: 1400px) {
  .header-nav__item {
    margin: 0 5px;
  }
}

@media (--viewport-tablet) {
  .header__container.is-nav-opened {
    background: var(--color-white);
    box-shadow: var(--shadow-default);
  }

  .header-nav {
    position: static;
    transform: none;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
  }

  .header__container.is-nav-opened .header-nav {
    max-height: 160px;
  }

  .header-nav__wrapper {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .header-nav__item {
    margin: 0;
    padding: 5px 10px;
    text-align: center;
  }

  .is-nav-opened .header-nav__item {
    color: var(--color-text-dark);
  }

  .header-nav-toggle {
    display: block;
    appearance: none;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    background-image: url('icons/hamburger_light.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    box-shadow: none;
    outline: 0;
  }

  .has-scrolled .header-nav-toggle {
    background-image: url('icons/hamburger_dark.svg');
  }

  .header__container.is-nav-opened .header-nav-toggle {
    background-image: url('icons/cross_dark.svg');
  }

  .header-nav-toggle.focus-visible {
    background-color: var(--color-focus);
  }
}

@media (--viewport-small-mobile) {
  .header-nav {
    transition: none;
  }

  .header__container.is-nav-opened {
    height: 100vh;
    background: var(--gradient-blue);
  }

  .header__container.is-nav-opened .header-nav-toggle {
    background-image: url('icons/cross_light.svg');
  }

  .header-nav__wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header__container.is-nav-opened .header-nav {
    max-height: none;
  }

  .header__wrapper {
    position: relative;
  }

  .is-nav-opened .header-nav__item {
    color: var(--color-text-light);
  }
}
