.tile__group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tile {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 60px 80px;
  margin-top: 60px;
  background-color: var(--color-white);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: var(--color-text-dark);
}

.tile:first-child {
  margin-right: 30px;
}

.tile__header {
  display: flex;
  align-items: center;
  min-height: 72px;
}

.tile__icon {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.tile__icon--spiral {
  height: 72px;
  min-width: 53px;
  max-height: 100px;
  background-image: url("icons/spiral.svg");
}

.tile__icon--roadrunner {
  height: 46px;
  min-width: 72px;
  background-image: url("icons/roadrunner.svg");
}

.tile__title {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-left: 24px;
  color: var(--color-text-dark);
}

.tile__content {
  height: 100%;
  margin-top: 24px;
}

.tile__subtitle {
  font-size: 20px;
  line-height: 24px;
}

.tile__text {
  margin-top: 12px;
}

.tile__action {
  width: 210px;
  margin-top: 24px;
}

@media (--viewport-small-desktop) {
  .tile {
    padding: 60px 70px;
  }
}

@media (--viewport-tablet) {
  .tile {
    max-width: 350px;
    padding: 60px 50px;
  }
}

@media (--viewport-mobile) {
  .tile__group {
    flex-direction: column;
    align-items: center;
  }

  .tile {
    max-width: 300px;
    padding: 60px 40px;
  }

  .tile:first-child {
    margin-right: 0;
  }

  .tile__text {
    font-size: 15px;
    line-height: 20px;
  }
}

@media (--viewport-small-mobile) {
  .tile {
    max-width: calc(100% - 80px);
    padding: 50px 30px;
  }

  .tile__title {
    font-size: 22px;
    line-height: 28px;
  }
}
