.post {
  display: flex;
  padding: 15px 0;
}

.post__image {
  display: flex;
  flex-shrink: 0;
  margin-right: 30px;
  width: 180px;
}

.post__image > img {
  display: block;
  border-radius: 4px;
}

.post__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post__title {
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text-light);
}

.post__text {
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-light);
}

@media (--viewport-small-desktop) {
  .post {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .post__image {
    margin-bottom: 10px;
  }
}
