@custom-media --viewport-small-desktop (width <= 1280px);
@custom-media --viewport-tablet (width <= 980px);
@custom-media --viewport-mobile (width <= 768px);
@custom-media --viewport-small-mobile (width <= 576px);
@custom-media --viewport-small-mobile-landscape (height <=576px);

:root {
  --color-black: #000;
  --color-white: #FFF;
  --color-dark: #131313;
  --color-light: #F8F8F8;


  --color-blue800: #2C66FA;
  --color-blue700: #2A61ED;
  --color-blue600: #3C55D6;
  --color-blue500: #153AC2;
  --color-blue400: #163ECF;

  --color-background-light: rgba(255, 255, 255, 0.1);

  --color-gray1000: #F6F6F8;
  --color-gray900: #F2F2F7;
  --color-gray800: #F0F0F0;
  --color-gray700: #CFD1D8;
  --color-gray600: #8D92A6;
  --color-gray500: #9898B3;

  --color-green800: #36d997;
  --color-green700: #33cb8d;
  --color-green600: #30bf86;
  --color-green500: #2db37d;

  --color-text-dark: #474e66;
  --color-text-default: #282828;
  --color-text-light: #fff;

  --color-primary: var(--color-green500);
  --color-primary-lighter: var(--color-green600);
  --color-primary-lightest: var(--color-green700);

  --color-link: var(--color-primary);
  --color-link-hover: var(--color-primary-lighter);

  --color-doc-nav-link: var(--color-text-dark);
  --color-doc-nav-link-hover: var(--color-primary);
  --color-doc-nav-link-active: var(--color-primary);

  --color-error: #FF8C8C;
  --color-hr: rgb(160 160 160 / 15%);

  --color-tabs-bg: #2d3748;
  --color-tabs-border-color: #2d3748;
  --color-tabs-bg-active: var(--color-light);
  --color-tab-text-inactive: rgb(255 255 255 / 60%);
  --color-tab-text-active: rgb(255 255 255 / 80%);

  --color-footer-copyright: #EBEBF2;
  --color-footer-recaptcha: #EBEBF2;

  --color-focus: #c5dbf1;

  --code-font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

  --color-terminal-bg: #f9f9f9;
  --color-terminal-tabs-bg: white;
  --color-terminal-border: #d9d9d9;
  --color-terminal-title: #acacac;
  --color-terminal-title-circle: url(data:image/svg+xml;utf8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNy41IiBmaWxsPSIjZTllOWU5IiBzdHJva2U9IiNkOWQ5ZDkiLz48L3N2Zz4=),
  url(data:image/svg+xml;utf8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNy41IiBmaWxsPSIjZTllOWU5IiBzdHJva2U9IiNkOWQ5ZDkiLz48L3N2Zz4=),
  url(data:image/svg+xml;utf8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNy41IiBmaWxsPSIjZTllOWU5IiBzdHJva2U9IiNkOWQ5ZDkiLz48L3N2Zz4=);
  --color-terminal-bash-binary: #4b00ff;
  --color-terminal-bash-option: #969797;
  --color-terminal-bash-default: var(--color-text-default);
  --color-terminal-bash-pacakge: #65b63b;

  --color-terminal-bash-bg-transparent: transparent;
  --color-terminal-bash-yellow: #b6a918;
  --color-terminal-bash-white: inherit;
  --color-terminal-bash-green: #3f8f16;

  --color-terminal-codeblock-lang-text: var(--color-primary);
  --color-terminal-codeblock-lang-bg: var(--color-white);
  --color-terminal-codeblock-lang-border: 1px solid var(--color-gray700);

  --blockquote-code-bg-color: rgb(255 255 255 / 40%);
  --blockquote-note-background-color: rgb(147 197 253 / 20%);
  --blockquote-note-border-color: #60a5fa;
  --blockquote-note-text-color: var(--color-black);

  --blockquote-info-background-color: rgb(74 222 128 / 20%);
  --blockquote-info-border-color: #22c55e;
  --blockquote-info-text-color: var(--color-black);

  --blockquote-warning-background-color: rgb(251 191 36 / 20%);
  --blockquote-warning-border-color: #f59e0b;
  --blockquote-warning-text-color: var(--color-black);

  --blockquote-danger-background-color: rgb(248 113 113 / 20%);
  --blockquote-danger-border-color: #ef4444;
  --blockquote-danger-text-color: var(--color-black);

  --gradient-green: linear-gradient(135deg, var(--color-green700), var(--color-green500));
  --gradient-green-light: linear-gradient(135deg, var(--color-green800), var(--color-green600));
  --gradient-blue: linear-gradient(135deg, var(--color-blue700), var(--color-blue500));
  --gradient-blue-light: linear-gradient(135deg, var(--color-blue800), var(--color-blue400));

  --shadow-default: 10px 10px 30px rgba(0, 0, 0, 0.1);
  --shadow-btn-default: 5px 5px 15px rgba(0, 0, 0, 0.2);
  --shadow-hover: 3px 3px 10px rgba(0, 0, 0, 0.2);
  --shadow-active: 1px 1px 2px rgba(0, 0, 0, 0.2);

  --width-wrapper-max: 1180px;
  --width-text-max: 780px;

  --header-height-main: 140px;
  --header-height: 90px;

  --scroll-percent: 0%;
  --scroll-color: var(--color-primary);

  --border-radius: .3em;

  --color-header-bg: var(--color-white);
  --color-header-border: 0;

  /** Dropdown */
  --color-dropdown-bg: #f9f9f9;
  --color-dropdown-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  --color-dropdown-link-text: var(--color-text-default);
  --color-dropdown-link-hover-bg: whitesmoke;

  /** Details */
  --color-details-bg: rgb(229 239 255 / 50%);
  --color-details-border: #cae2ff;
  --color-details-summary-text: #415099;

  /** Search */
  --color-search-text: var(--color-text-default);
  --color-search-placeholder: var(--color-text-light);
  --color-search-bg-focus: transparent;
  --color-search-result-bg: var(--color-white);
  --color-search-result-shadow: var(--color-white);
  --color-search-result-text: var(--color-text-default);
  --color-search-result-separator: var(--color-gray800);
  --color-search-result-focus: var(--color-light);
  --color-search-result-link: var(--color-primary);

  /** Highlight.js */
  --color-hljs-default: #24292e;
  --color-hljs-keyword: #d73a49;
  --color-hljs-title: #6f42c1;
  --color-hljs-attribute: #0072ff;
  --color-hljs-string: #032f62;
  --color-hljs-symbol: #e36209;
  --color-hljs-comment: #6a737d;
  --color-hljs-name: #22863a;
  --color-hljs-subst: #24292e;
  --color-hljs-section: #005cc5;
  --color-hljs-bullet: #735c0f;
  --color-hljs-emphasis: #24292e;
  --color-hljs-strong: #24292e;
  --color-hljs-addition: #078124;
  --color-hljs-addition-bg: #cbffd9;
  --color-hljs-deletion: #91000b;
  --color-hljs-deletion-bg: #ffdee1;

  --color-hljs-copy-icon-text: var(--color-text-default);
  --color-hljs-copy-bg: transparent;
  --color-hljs-copy-icon: url('data:image/svg+xml;utf-8,<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H16C15.4477 5 15 4.55228 15 4C15 3.44772 15.4477 3 16 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H6Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C7 1.89543 7.89543 1 9 1H15C16.1046 1 17 1.89543 17 3V5C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5V3ZM15 3H9V5H15V3Z" fill="black"/></svg>');
  --color-hljs-copied-icon: url('data:image/svg+xml;utf-8,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 6.47059L6.42857 10L11 4" stroke="black" stroke-width="2" stroke-linecap="green" stroke-linejoin="round"/></svg>');

  --color-hljs-image-icon-text: var(--color-text-default);
  --color-hljs-image-bg: transparent;
  --color-hljs-image-icon: url('data:image/svg+xml;utf-8,<svg width="13" height="13" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M66.6667 100C66.6667 81.5905 81.5905 66.6667 100 66.6667H500C518.409 66.6667 533.333 81.5905 533.333 100V302.86L499.023 268.55C471.565 241.091 426.539 242.816 401.265 272.304L401.264 272.304L348.165 334.254L315.273 298.087C289.33 269.561 244.4 269.761 218.711 298.516L66.6667 468.716V100ZM520.373 523.606L393.368 383.957L451.882 315.69H451.882L451.883 315.69L533.333 397.14V500C533.333 510.086 528.853 519.126 521.775 525.239C521.326 524.685 520.859 524.14 520.373 523.606ZM267.246 355.263L429.193 533.333H108.169L267.246 355.263ZM100 0C44.7715 0 0 44.7715 0 100V500C0 555.229 44.7715 600 100 600H500C555.229 600 600 555.229 600 500V100C600 44.7715 555.229 0 500 0H100ZM183.333 116.667C146.514 116.667 116.667 146.514 116.667 183.333C116.667 220.152 146.514 250 183.333 250C220.152 250 250 220.152 250 183.333C250 146.514 220.152 116.667 183.333 116.667Z" fill="black"/></svg>');

  /** Doc sections */
  --documentation-section-bg: transparent;
  --documentation-section-bg-hover: rgba(171, 242, 255, 0.2);
  --documentation-section-border: transparent;
  --documentation-section-icon-color: var(--color-primary);
  --documentation-section-link-color: var(--color-text-dark);
  --documentation-section-link-hover-color: var(--color-primary);
  --documentation-section-link-hover-bg: rgba(171, 242, 255, 0.4);

  --documentation-edit-link-color: var(--color-primary);
  --documentation-edit-link-opacity: 0.9;
  --documentation-edit-link-opacity-hover: 1;
}

[data-theme="dark"] {
  --color-black: rgb(148 163 184/1);
  --color-white: #161b22;
  --color-dark: #F8F8F8;
  --color-light: #131313;
  --color-gray900: #000000;
  --color-gray800: #0E0E0E;
  --color-gray600: #494949;
  --color-gray500: #666;

  --color-text-default: #a2acba;
  --color-text-light: #627187;
  --color-text-dark: #8994a4;

  --color-doc-nav-link: var(--color-text-light);
  --color-doc-nav-link-hover: var(--color-primary);
  --color-doc-nav-link-active: var(--color-primary);

  --scroll-color: rgba(34, 255, 0, 0.15);

  --color-tabs-border-color: #343f55;
  --color-tabs-bg-active: #18202a;

  --color-footer-copyright: var(--color-gray800);
  --color-footer-recaptcha: var(--color-gray800);

  --color-terminal-bg: #2d3748;
  --color-terminal-tabs-bg: var(--color-terminal-bg);
  --color-terminal-title: #93a4c9;
  --color-terminal-border: #4d4d64;
  --color-terminal-bash-binary: #8ab4ff;
  --color-terminal-bash-option: #969797;
  --color-terminal-bash-default: #e7e7e7;
  --color-terminal-bash-pacakge: #65b63b;
  --color-terminal-title-circle: url(data:image/svg+xml;utf8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNy41IiBmaWxsPSIjNEE1NTZCIiBzdHJva2U9IiMwMDJCNDEiLz48L3N2Zz4=),
  url(data:image/svg+xml;utf8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNy41IiBmaWxsPSIjNEE1NTZCIiBzdHJva2U9IiMwMDJCNDEiLz48L3N2Zz4=),
  url(data:image/svg+xml;utf8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNy41IiBmaWxsPSIjNEE1NTZCIiBzdHJva2U9IiMwMDJCNDEiLz48L3N2Zz4=);

  --blockquote-code-bg-color: rgb(255 255 255 / 10%);
  --blockquote-danger-text-color: var(--color-black);
  --blockquote-warning-text-color: var(--color-black);
  --blockquote-info-text-color: var(--color-black);
  --blockquote-note-text-color: var(--color-black);

  --color-focus: #6A9FD9; /* rgba(106, 159, 217, 0.3); */

  --color-doc-content-bg: var(--color-white);
  --color-doc-inline-code-bg: var(--color-gray800);

  --color-doc-inline-code-text: rgba(255, 255, 255, 0.80);

  --shadow-default: 0 8px 18px rgba(0, 0, 0, 0.2);
  --shadow-bottom: 0px 10px 17px -12px rgba(0, 0, 0, 0.2);

  --color-header-border: 1px solid #252d38;

  /** Details */
  --color-details-bg: rgb(112 167 255 / 10%);
  --color-details-border: rgb(112 167 255 / 15%);
  --color-details-summary-text: #989fbf;

  /** Search */
  --color-search-text: #9aaac1;
  --color-search-placeholder: rgb(162 172 186 / 50%);
  --color-search-bg-focus: var(--color-light);
  --color-search-result-bg: #212934;
  --color-search-result-shadow: var(--color-white);
  --color-search-result-text: var(--color-text-default);
  --color-search-result-separator: var(--color-gray800);
  --color-search-result-focus: var(--color-light);
  --color-search-result-link: var(--color-primary);

  /** Highlight.js */
  --color-hljs-default: #adbac7;
  --color-hljs-keyword: #f47067;
  --color-hljs-title: #dcbdfb;
  --color-hljs-attribute: #6cb6ff;
  --color-hljs-string: #96d0ff;
  --color-hljs-symbol: #f69d50;
  --color-hljs-comment: #768390;
  --color-hljs-name: #8ddb8c;
  --color-hljs-subst: #adbac7;
  --color-hljs-section: #316dca;
  --color-hljs-bullet: #eac55f;
  --color-hljs-emphasis: #adbac7;
  --color-hljs-strong: #adbac7;
  --color-hljs-addition: #b4f1b4;
  --color-hljs-addition-bg: #1b4721;
  --color-hljs-deletion: #ffd8d3;
  --color-hljs-deletion-bg: #78191b;

  --color-hljs-copy-icon-text: rgba(255, 255, 255, 0.80);
  --color-hljs-copy-bg: transparent;
  --color-hljs-copy-icon: url('data:image/svg+xml;utf-8,<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H16C15.4477 5 15 4.55228 15 4C15 3.44772 15.4477 3 16 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H6Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C7 1.89543 7.89543 1 9 1H15C16.1046 1 17 1.89543 17 3V5C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5V3ZM15 3H9V5H15V3Z" fill="white"/></svg>');
  --color-hljs-copied-icon: url('data:image/svg+xml;utf-8,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 6.47059L6.42857 10L11 4" stroke="green" stroke-width="2" stroke-linejoin="round"/></svg>');

  --color-hljs-image-icon-text: rgba(255, 255, 255, 0.80);
  --color-hljs-image-bg: transparent;
  --color-hljs-image-icon: url('data:image/svg+xml;utf-8,<svg width="13" height="13" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M66.6667 100C66.6667 81.5905 81.5905 66.6667 100 66.6667H500C518.409 66.6667 533.333 81.5905 533.333 100V302.86L499.023 268.55C471.565 241.091 426.539 242.816 401.265 272.304L401.264 272.304L348.165 334.254L315.273 298.087C289.33 269.561 244.4 269.761 218.711 298.516L66.6667 468.716V100ZM520.373 523.606L393.368 383.957L451.882 315.69H451.882L451.883 315.69L533.333 397.14V500C533.333 510.086 528.853 519.126 521.775 525.239C521.326 524.685 520.859 524.14 520.373 523.606ZM267.246 355.263L429.193 533.333H108.169L267.246 355.263ZM100 0C44.7715 0 0 44.7715 0 100V500C0 555.229 44.7715 600 100 600H500C555.229 600 600 555.229 600 500V100C600 44.7715 555.229 0 500 0H100ZM183.333 116.667C146.514 116.667 116.667 146.514 116.667 183.333C116.667 220.152 146.514 250 183.333 250C220.152 250 250 220.152 250 183.333C250 146.514 220.152 116.667 183.333 116.667Z" fill="white"/></svg>');

  /** Dropdown */
  --color-dropdown-bg: #212934;
  --color-dropdown-link-text: var(--color-primary);
  --color-dropdown-link-hover-bg: var(--color-light);

  /** Doc sections */
  --documentation-section-bg-hover: #20252c;
  --documentation-section-border: 3px solid var(--color-white);
  --documentation-section-icon-color: var(--color-text-light);
  --documentation-section-link-color: var(--color-text-light);
  --documentation-section-link-hover-color: var(--color-primary);

  --documentation-edit-link-opacity: 0.3;
  --documentation-edit-link-opacity-hover: 0.7;
}

html {
  width: 100%;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

html[data-theme="dark"] {
  color-scheme: dark;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
}

a {
  color: var(--color-link);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--color-link-hover);
}

button {
  border: none;
}

.no-scroll {
  overflow: hidden;
}

.hide {
  display: none !important;
}

.icon-logo:before {
    content: "\e900";
}

.icon-submit:before {
    content:"\E901"
}
.icon-mail:before {
    content:"\E902"
}
.icon-checkbox:before {
    content:"\E903"
}
.icon-point:before {
    content:"\E904"
}
.icon-github:before {
    content:"\F09B"
}
.icon-search:before {
    content:"\F002"
}
.icon-pencil:before {
    content:"\F040"
}

.icon-logo {
    font-size: 22px;
}

.icon-github {
    font-size: 27px;
}

.has-scrolled .icon-logo, .is-inner .icon-logo  {
    color: #262b44;
}

.text-white {
    color: #fff !important;
}

[class*=" icon-"],
[class^=icon-] {
  font-family:icons!important;
  speak:none;
  font-style:normal;
  font-weight:400;
  font-variant:normal;
  text-transform:none;
  line-height:1;
  vertical-align:middle;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

@font-face {
 font-family: 'Proxima Nova';
 src: url('../fonts/ProximaNova-Regular-subset.woff2') format('woff2'),
      url('../fonts/ProximaNova-Regular-subset.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
 font-family: 'Proxima Nova';
 src: url('../fonts/ProximaNova-RegularItalic-subset.woff2') format('woff2'),
      url('../fonts/ProximaNova-RegularItalic-subset.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
 font-family: 'Proxima Nova';
 src: url('../fonts/ProximaNova-Sbold-subset.woff2') format('woff2'),
      url('../fonts/ProximaNova-Sbold-subset.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
 font-family: 'Proxima Nova';
 src: url('../fonts/ProximaNova-Bold-subset.woff2') format('woff2'),
      url('../fonts/ProximaNova-Bold-subset.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'icons';
    src:  url('../fonts/icons.eot?27rvfu');
    src:  url('../fonts/icons.eot?27rvfu#iefix') format('embedded-opentype'),
    url('../fonts/icons.ttf?27rvfu') format('truetype'),
    url('../fonts/icons.woff?27rvfu') format('woff'),
    url('../fonts/icons.svg?27rvfu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media (--viewport-small-desktop) {
  :root {
    --width-wrapper-max: 90%;
  }
}
@media (--viewport-tablet) {
  :root {
    --header-height-main: 120px;
    --header-height: 70px;
  }
}
@media (--viewport-small-mobile-landscape) {
  :root {
    --header-height-main: 70px;
    --header-height: 70px;
  }
}
@media (--viewport-small-mobile) {
  :root {
    --header-height-main: 100px;
    --header-height: 50px;
  }
}
