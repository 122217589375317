.footer {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: var(--color-gray900);
}

.footer .logo {
  margin: -4px 0 0 -2px;
}

.footer__main {
  padding: 40px 0;
}

.footer__wrapper {
  position: relative;
  display: flex;
  max-width: var(--width-wrapper-max);
  margin: 0 auto;
}

.footer__column {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__column--about {
  width: 25%;
}

.footer__column--subscribe {
  width: 35%;
}

.footer__title {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: var(--color-text-dark);
  text-transform: uppercase;
}

.footer__text {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-gray600);
}

.footer-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-text-dark);
}

.footer-nav:first-child {
  margin-top: 30px;
}

.footer-nav__item {
  margin: 10px 0 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
  text-decoration: none;
}

.footer-nav__item:hover {
  color: var(--color-link-hover);
}

.footer-nav__item:focus {
  outline: 0;
}

.footer-nav__item.focus-visible {
  background-color: var(--color-focus);
}

.footer-sponsor {
  margin-top: 20px;
}

.footer-sponsor__title {
  margin: 0 0 10px;
  font-size: 14px;
  color: var(--color-gray500);
}

.footer-sponsor__logo {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: var(--color-text-dark);
  text-decoration: none;
}

.footer-sponsor__logo:focus {
  outline: 0;
}

.footer-sponsor__logo.focus-visible {
  background-color: var(--color-focus);
}

.footer__copyright {
  padding: 10px;
  background-color: var(--color-footer-copyright);
  font-size: 16px;
  line-height: 30px;
  color: var(--color-gray600);
  text-align: center;
}

.grecaptcha-badge { visibility: hidden; }
.footer__recaptcha {
    padding: 0 10px 10px;
    background-color:  var(--color-footer-recaptcha);
    font-size: 75%;
    line-height: 30px;
    color: var(--color-gray600);
    text-align: center;
}

@media (--viewport-tablet) {
  .footer__wrapper {
    flex-wrap: wrap;
  }

  .footer__column {
    width: 35%;
  }

  .footer__column--about {
    width: 30%;
  }

  .footer__column--subscribe {
    margin-top: 40px;
    margin-left: 30%;
    width: 70%;
  }
}

@media (--viewport-mobile) {
  .footer__column {
    width: 30%;
  }

  .footer__column--about {
    width: 40%;
  }

  .footer__column--subscribe {
    margin-left: 0;
    width: 100%;
    max-width: 600px;
  }
}

@media (--viewport-small-mobile) {
  .footer__column {
    width: 50%;
    margin-top: 40px;
  }

  .footer__column--about {
    width: 100%;
    margin-top: 0;
  }

  .footer__column--subscribe {
    width: 100%;
  }
}
